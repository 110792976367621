import { MAINTAIN_PERMISSION, DELETE_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL, STERILIZATION_SPECIFICATIONS_BRITESTACK } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Britestack", "ph-search-by": "Search by Inventory Code", "search-by": "BSItemCode" }, scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) || _vm.canEditTrial ? { key: "create-buttons", fn: function(ref) {
    var onClick = ref.onClick;
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": onClick } }, [_vm._v("Add Britestack")])];
  } } : null, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.delete) || _vm.canDeleteTrial && record.trialFlag ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", class: { "mr-1": record.isInactive, "mr-2": !record.isInactive }, attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "disabled": record.isInactive, "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }], null, true) }, [_c("url-field", { key: "BSItemCode", attrs: { "title": "Inventory Code", "base-url": "/sterilization-specifications/britestacks", "data-index": "bsItemCode", "width": 133, "sorter": true, "clickable": _vm.can(_vm.permissions.maintain) } }), _c("icon-field", { key: "TrialFlag", attrs: { "title": "Trial", "align": "center", "data-index": "trialFlag", "render-icon": _vm.renderIcon, "width": 81, "sorter": true } }), _c("text-field", { key: "ItemDescription", attrs: { "title": "Description", "data-index": "itemDescription" } }), _c("text-field", { key: "ABCCode", attrs: { "title": "ABC Code", "data-index": "abcCode" } }), _c("text-field", { key: "ListCatalog", attrs: { "title": "List Catalog", "data-index": "listCatalog", "width": 126, "sorter": true } }), _c("icon-field", { key: "ContainsMeat", attrs: { "title": "Contain Meat", "align": "center", "data-index": "containsMeat", "render-icon": _vm.renderIcon, "width": 135, "sorter": true } }), _c("text-field", { key: "CanSize", attrs: { "title": "Can Size", "data-index": "canSize", "width": 139, "sorter": true } }), _c("text-field", { key: "NoOfAttachment", attrs: { "title": "No of Att", "align": "right", "data-index": "noOfAttachment", "width": 100 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListBritestacks",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION,
        delete: DELETE_PERMISSION
      }
    };
  },
  computed: {
    canEditTrial() {
      return this.$can(this.permissions.maintain, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL);
    },
    canDeleteTrial() {
      return this.$can(this.permissions.delete, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL);
    }
  },
  methods: {
    renderIcon(value) {
      if (value == 0) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListBritestacks = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-britestacks" }, [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.britestacks", "api-url": _vm.apiUrl, "create-route": "/sterilization-specifications/britestacks/create", "edit-route": "/sterilization-specifications/britestacks/:id", "filter-route": "/sterilization-specifications/britestacks/advanced-search" } }, [_c("list-britestacks")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListBritestacks
  },
  data() {
    return {
      page: STERILIZATION_SPECIFICATIONS_BRITESTACK,
      apiUrl,
      menuItems: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "britestack",
          title: "Britestack",
          path: "/sterilization-specifications/britestacks"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
